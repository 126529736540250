
.default {
    border: 1px solid #007BFF; 
    background: #fff;
    padding: 3px 10px; 
    border-radius: 50px; 
    color: #007BFF;
}

.active-link{
    padding: 5px 15px;
    border-radius: 50px;
    background-color:#007BFF ;
    border: none;
    color: #fff;
}