.header {
    display: flex;
    justify-content: space-between;
}

ul, li {
    list-style: none;
}

.logo {
    text-decoration: none;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 15px;
}

.menu {
    display: flex;
    gap: 30px;
    padding: 15px;
    align-items: center;
}

/*ul a {*/
/*    text-decoration: none;*/
/*    color: #212121;*/
/*}*/

.active {
    border-bottom: 2px solid #007BFF;
}
.headerLink:focus{
    color: #2603FF!important;
}
.respHamburger, .respMenu{
    display: none;
}


.close{
    position: absolute;
    right: 0;
    z-index: 2;
}

.close svg{
    color: red;
    font-size: 29px;
}

.respMenu2, .fon2{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    right: 0;
    top: -100%;
    transition: .3s;
}

.active-link{
    padding: 3px 10px;
}

@media (max-width: 651px) {
    .respHamburger, .respMenu{
        display: inline;
    }

    .respMenu{
        position: relative;
        z-index: 2;
    }

    .fon{
        display:flex;
        top: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.7);
        right: 0;
        transition: .3s;
    }

    .respMenu ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        right: 0;
        width: 177px;
        background-color: white;
        padding: 16px 0;
        border-radius: 8px;
    }

    .respMenu ul a{
        color: black;
    }

    .respMenu ul li{
        margin: 5px 0;
    }

    .respHamburger svg{
        font-size: 32px;
        color: black;
        cursor: pointer;
    }

    .menu{
        display: none;

    }
}

