

.footerwrapp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    z-index: -1;
}

ul, li {
    list-style: none;
    line-height: 24px;
    font-weight: 500;
}

li a {
    color: #212121;

}

.footerlinks a:hover {
    border-bottom: 2px solid #2603FF;
}

.footerlogobox h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0.05em;
    margin-bottom: 25px;
}

.footerlogobox p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #424242;
    width: 350px;
    font-family: 'Poppins', sans-serif;
}

a {
    text-decoration: none;
}

.coltitle {
    font-weight: 600;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #007BFF;;
    margin-bottom: 12px;
}
@media (max-width: 540px){
    footer{
        padding-top: 35px;
    }}


   
